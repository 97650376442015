import styled, { css } from "styled-components"
import {
  useSessionDevice,
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { version, environment } from "../../config"
import { ReactNode, useEffect } from "react"
import { useRealtimeSession } from "../../hooks/use-realtime-session"
import PageLoader from "@hornet-web-react/core/components/UI/PageLoader"
import NiceModal from "@ebay/nice-modal-react"
import { debug } from "@hornet-web-react/core/utils"
import { useCookieConsent } from "@hornet-web-react/core/hooks/use-cookie-consent"
import { useHornetSession } from "../../hooks/use-hornet-session"

const isInAppStyle = css`
  background: ${({ theme }) => theme.color.bg.light03};
  padding-top: 0;
`

const Wrapper = styled.div<{
  isInApp: boolean
  hasTopNavBar: boolean
  hasBottomNavBar: boolean
}>`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  overflow-x: hidden; // just a safety catch against overflowing content
  padding-top: ${({ theme, hasTopNavBar }) =>
    hasTopNavBar ? theme.height.topNavBar : 0};
  ${(props) => props.isInApp && isInAppStyle};

  // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-bottom: constant(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    padding-bottom: calc(
      ${({ hasBottomNavBar }) => (hasBottomNavBar ? 50 : 0)}px +
        var(--safe-area-inset-bottom)
    );
  }
  @supports (padding-top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(
      ${({ theme, hasTopNavBar }) =>
          hasTopNavBar ? theme.height.topNavBar : "0px"} +
        var(--safe-area-inset-top)
    );
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding-bottom: calc(
      ${({ hasBottomNavBar }) => (hasBottomNavBar ? 50 : 0)}px +
        var(--safe-area-inset-bottom)
    );
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(
      ${({ theme, hasTopNavBar }) =>
          hasTopNavBar ? theme.height.topNavBar : "0px"} +
        var(--safe-area-inset-top)
    );
  }

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: constant(safe-area-inset-bottom);
      padding-bottom: var(--safe-area-inset-bottom);
    }

    // Browsers which fully support CSS Environment variables (iOS 11.2+).
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      padding-bottom: var(--safe-area-inset-bottom);
    }
  }
`

const Main = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const DebugPanel = styled.div`
  display: none;
`

type AppContainerProps = {
  children: ReactNode
  hasTopNavBar: boolean
  hasBottomNavBar: boolean
}

export default function AppContainer({
  children,
  hasTopNavBar,
  hasBottomNavBar,
}: AppContainerProps) {
  const { isAuthenticated } = useSessionUser()
  const { isInApp } = useSessionEnvironment()
  const { locale } = useSessionDevice()
  const { isSet, isLoading } = useCookieConsent()
  const { markAccountAsVerified } = useHornetSession()

  const debugString = [version, locale, environment].join(";")

  const { subscribe: subscribeToSession, unsubscribe: unsubscribeFromSession } =
    useRealtimeSession({ markAccountAsVerified })

  // websocket subscriptions
  useEffect(() => {
    debug(
      "AppContainer: subscribeToSession, isAuthenticated: " +
        (isAuthenticated ? "yes" : "no")
    )

    if (!isAuthenticated) {
      return
    }

    subscribeToSession()

    return () => {
      unsubscribeFromSession()
    }
  }, [isAuthenticated, subscribeToSession, unsubscribeFromSession])

  useEffect(() => {
    if (!isSet && !isLoading && !isInApp) {
      const handleOpenPrivacyManager = async () => {
        const PrivacyManagerModal = (
          await import("../Modals/PrivacyManagerModal")
        ).default

        void NiceModal.show(PrivacyManagerModal)
      }

      void handleOpenPrivacyManager()
    }
  }, [isSet, isLoading, isInApp])

  return (
    <Wrapper
      isInApp={isInApp}
      hasTopNavBar={hasTopNavBar}
      hasBottomNavBar={hasBottomNavBar}
    >
      <Main>
        <PageLoader />
        {children}
      </Main>
      <DebugPanel id="dbg" aria-hidden="true">
        {debugString}
      </DebugPanel>
    </Wrapper>
  )
}
