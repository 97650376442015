import { FC } from "react"
import DiscoverActiveIcon from "@hornet-web-react/core/components/Icons/DiscoverActiveIcon"
import DiscoverIcon from "@hornet-web-react/core/components/Icons/DiscoverIcon"
import ChatActiveIcon from "@hornet-web-react/core/components/Icons/ChatActiveIcon"
import ChatIcon from "@hornet-web-react/core/components/Icons/ChatIcon"
import ShoppingCartActiveIcon from "@hornet-web-react/core/components/Icons/ShoppingCartActiveIcon"
import ShoppingCartIcon from "@hornet-web-react/core/components/Icons/ShoppingCartIcon"
import MemberAvatar from "@hornet-web-react/core/components/UI/MemberAvatar"
import styled, { useTheme } from "styled-components"
import Link from "next/link"
import UnstyledButton from "@hornet-web-react/core/components/UI/UnstyledButton"
import OrangeDot from "@hornet-web-react/core/components/UI/OrangeDot"
import NiceModal from "@ebay/nice-modal-react"
import HoneyIcon from "@hornet-web-react/core/components/Icons/HoneyIcon"
import {
  Cog6ToothIcon,
  EyeIcon,
  EyeSlashIcon,
  MinusCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid"
import { FaCrown } from "react-icons/fa"
import PremiumIcon from "@hornet-web-react/core/components/Icons/PremiumIcon"
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline"
import appConfig from "../../../app-config"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import invariant from "tiny-invariant"
import { ApiService } from "@hornet-web-react/core/services/API"
import { isRight } from "@hornet-web-react/core/utils"
import { PremiumPaywall } from "@hornet-web-react/core/types"
import useTranslation from "next-translate/useTranslation"
import { useRouter } from "next/router"
import {
  useSessionApi,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { useSessionTotals } from "@hornet-web-react/core/hooks/use-session-totals"
import { useHornetSession } from "../../../hooks/use-hornet-session"
import { useMember } from "@hornet-web-react/core/hooks/use-member"
import { useNavigation } from "../../../hooks/use-navigation"
import { usePremium } from "../../../hooks/use-premium"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useFlashMessage } from "@hornet-web-react/core/hooks/use-flash-message"
import NotificationsButton from "./NotificationsButton"
import { useEventTrackerService } from "../../../hooks/use-event-tracker-service"
import HornetAnalyticsEvent from "../../../models/hornet-analytics-event"

type NavBarProps = {
  className?: string
  hasNotifications?: boolean
}

const i18nKey = "hornet:components.layout.nav_bar.nav_bar"

const NavBar: FC<NavBarProps> = ({ className, hasNotifications = true }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const router = useRouter()
  const { currentUser } = useSessionUser()
  const { data: sessionTotals } = useSessionTotals()
  const { data: fullSession, mutate } = useHornetSession()
  const { data: currentMember } = useMember(fullSession?.profile.profileId)
  const { navigateToProfile, navigateToAccount } = useNavigation()
  const {
    user: { logout },
  } = useSessionApi()
  const { openPremiumModal } = usePremium()
  const { makeApiRequest } = useApi()
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)
  const { showOops } = useFlashMessage()
  const { reportEvent } = useEventTrackerService()

  const isMyProfileActive =
    router.route === "/profiles/[...data]" &&
    fullSession &&
    router.asPath.endsWith(fullSession.profile.profileId)
  const isGuysActive = router.route.includes("/grids")
  const isChatActive = router.route.includes("/inbox")
  const isNotificationsActive = router.route.includes("/notifications")

  const handleHoneyOnClick = async () => {
    const HoneyModal = (await import("../../Modals/HoneyModal")).default
    void NiceModal.show(HoneyModal, {})
  }

  const actionSheetModalItems = [
    {
      id: "honey",
      icon: <HoneyIcon width={"24px"} height={"26px"} />,
      label: t(`${i18nKey}.profile_actions.actions.honey`),
    },
    {
      id: "my_profile",
      icon: <UserCircleIcon color={theme.color.tint.default} width={24} />,
      label: t(`${i18nKey}.profile_actions.actions.my_profile`),
      isDisabled: !fullSession,
    },
    {
      id: "account",
      icon: <Cog6ToothIcon color={theme.color.tint.default} width={24} />,
      label: t(`${i18nKey}.profile_actions.actions.account`),
    },
    {
      id: "premium",
      icon: <FaCrown color={theme.color.tint.default} size={24} />,
      label: t(`${i18nKey}.profile_actions.actions.premium`),
    },
    {
      id: "blocked_users",
      icon: <MinusCircleIcon color={theme.color.tint.default} width={24} />,
      label: t(`${i18nKey}.profile_actions.actions.blocked_users`),
    },
    // hide for now
    // {
    //   id: "hidden_users",
    //   icon: <UserMinusIcon color={theme.color.tint.default} width={24} />,
    //   label: t(`${i18nKey}.profile_actions.actions.hidden_users`),
    // },
    {
      id: "invisible_mode",
      icon: currentUser?.hasPremium ? (
        fullSession?.isVisible ? (
          <EyeIcon color={theme.color.tint.default} width={24} />
        ) : (
          <EyeSlashIcon color={theme.color.tint.default} width={24} />
        )
      ) : (
        <PremiumIcon />
      ),
      label: currentUser?.hasPremium
        ? fullSession?.isVisible
          ? t(`${i18nKey}.profile_actions.actions.invisible_mode_is_off`)
          : t(`${i18nKey}.profile_actions.actions.invisible_mode_is_on`)
        : t(`${i18nKey}.profile_actions.actions.invisible_mode`),
      isDisabled: !fullSession,
    },
    {
      id: "log_out",
      icon: (
        <ArrowRightOnRectangleIcon
          color={theme.color.tint.default}
          width={24}
        />
      ),
      label: t(`${i18nKey}.profile_actions.actions.log_out`),
    },
  ]

  const openBlockedUsersModal = async () => {
    const BlockedMembersListModal = (
      await import(
        "@hornet-web-react/core/components-lazy/BlockedMembersListModal"
      )
    ).default

    void NiceModal.show(BlockedMembersListModal, {
      appConfig,
    })
  }

  const openHiddenFromFeedUsersModal = async () => {
    // need current member (logged-in user) to get to the hidden users
    if (!currentMember) {
      return
    }

    // TODO: this modal should be refactored to use new hook `useListMembers`
    // and new model `list-member.model` which will use the MicroMember serializer
    // on the API
    void NiceModal.show(
      (await import("@hornet-web-react/core/components-lazy/MembersListModal"))
        .default,
      {
        member: currentMember,
        endpoint: ApiServiceEndpoint.ExploreIgnoresGet,
        title: t(`${i18nKey}.profile_actions.actions.hidden_users`),
      }
    )
  }

  const toggleInvisibleMode = async () => {
    invariant(fullSession, "fullSession is required")

    const apiResult = await makeApiRequest(
      ApiService.getEndpoint(ApiServiceEndpoint.AccountProfilePut),
      {
        profile: {
          visible: !fullSession.isVisible,
        },
      },
      (error) => {
        if (error instanceof Error) {
          loggerService.logExceptionWithSentry(
            error,
            loggerService.createLoggingContext({
              component: "EditProfileModal",
              step: "handleProfileSave",
            })
          )
        }

        void showOops(error)
      }
    )

    if (isRight(apiResult)) {
      await mutate()
    }
  }

  const showActionSheetModal = async () => {
    const ActionSheetModal = (
      await import("@hornet-web-react/core/components-lazy/ActionSheetModal")
    ).default

    NiceModal.show(ActionSheetModal, {
      title: t(`${i18nKey}.profile_actions.title`),
      items: actionSheetModalItems,
    }).then((result: string) => {
      switch (result) {
        case "honey":
          handleHoneyOnClick()
          break

        case "my_profile":
          invariant(fullSession, "fullSession is required")

          // report my own profile
          void reportEvent(HornetAnalyticsEvent.navigationTapOnMyProfile())

          navigateToProfile(
            fullSession.profile.username,
            fullSession.profile.profileId
          )
          break

        case "account":
          navigateToAccount()
          break

        case "premium":
          openPremiumModal(PremiumPaywall.enum.menu)
          break

        case "invisible_mode":
          if (currentUser?.hasPremium === true) {
            toggleInvisibleMode()
            return
          }

          openPremiumModal(PremiumPaywall.enum.profile_invisible)
          break

        case "blocked_users":
          openBlockedUsersModal()
          break

        case "hidden_users":
          openHiddenFromFeedUsersModal()
          break

        case "log_out":
          logout()

          // hard refresh to clean up any SWR cache etc
          window.location.href = "/"
          break
      }
    })
  }

  return (
    <Items className={className}>
      <Item isActive={isGuysActive}>
        <ItemLink
          href="/grids/nearby"
          aria-label={t(`${i18nKey}.items.discover`)}
          onClick={() =>
            void reportEvent(HornetAnalyticsEvent.navigationTapOnGuys())
          }
        >
          {isGuysActive ? <DiscoverActiveIcon /> : <DiscoverIcon />}
        </ItemLink>
      </Item>
      <Item isActive={isChatActive}>
        <ItemLink
          href="/inbox"
          aria-label={t(`${i18nKey}.items.chat`)}
          onClick={() =>
            void reportEvent(HornetAnalyticsEvent.navigationTapOnChats())
          }
        >
          {isChatActive ? <ChatActiveIcon /> : <ChatIcon />}

          {sessionTotals?.unread_messages ? <UnreadDot /> : null}
        </ItemLink>
      </Item>
      <Item isActive={router.route.includes("/shop")}>
        <ItemLink
          href="/shop"
          aria-label={t(`${i18nKey}.items.shop`)}
          onClick={() =>
            void reportEvent(HornetAnalyticsEvent.navigationTapOnShop())
          }
        >
          {router.route.includes("/shop") ? (
            <ShoppingCartActiveIcon width={22} height={22} />
          ) : (
            <ShoppingCartIcon width={22} height={22} />
          )}
        </ItemLink>
      </Item>
      {hasNotifications && (
        <Item isActive={isNotificationsActive}>
          <NotificationsButton />
        </Item>
      )}
      <Item isActive={isMyProfileActive === true}>
        {fullSession ? (
          <ItemButton onClick={showActionSheetModal}>
            <MemberAvatarActive
              avatarUrl={fullSession.profile.profilePhoto?.thumbnailUrl}
              size={26}
              isActive={isMyProfileActive === true}
            />
          </ItemButton>
        ) : (
          <MemberAvatar avatarUrl={null} size={26} />
        )}
      </Item>
    </Items>
  )
}

export default NavBar

const Items = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Item = styled.div<{ isActive: boolean }>`
  width: 50px;
  height: 50px;
  text-align: center;
  position: relative;
  z-index: 40;
  background: #fff;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.tint.default : theme.color.text.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const ItemLink = styled(Link)`
  display: flex;
  color: inherit;
`

const ItemButton = styled(UnstyledButton)`
  display: flex;
  color: inherit;
`

const UnreadDot = styled(OrangeDot)`
  top: 13px;
  right: 10px;
`

const MemberAvatarActive = styled(MemberAvatar)<{ isActive: boolean }>`
  cursor: pointer;

  img {
    border: 1px solid white;
    box-shadow: 0 0 0 1px
      ${({ theme, isActive }) =>
        isActive ? theme.color.tint.default : "transparent"};
  }
`
