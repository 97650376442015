import { useCallback } from "react"
import NiceModal from "@ebay/nice-modal-react"
import { PremiumPaywall } from "@hornet-web-react/core/types"
import HornetAnalyticsEvent from "../models/hornet-analytics-event"
import { useSessionApi } from "@hornet-web-react/core/contexts/session"
import { useEventTrackerService } from "./use-event-tracker-service"

export function usePremium() {
  const {
    premium: { setPremiumPaywall },
  } = useSessionApi()
  const { reportEvent } = useEventTrackerService()

  return {
    openPremiumModal: useCallback(
      async (paywall: PremiumPaywall) => {
        const PremiumModal = (await import("../components/Modals/PremiumModal"))
          .default

        void reportEvent(HornetAnalyticsEvent.premiumOpened(paywall))

        setPremiumPaywall(paywall)

        NiceModal.show(PremiumModal, {
          paywall,
        }).catch(() => setPremiumPaywall(undefined))
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
  }
}
